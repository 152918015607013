import React, { useEffect, useState } from "react";
import { API } from "../../config/api";
import { GET, POST, PUT } from "../../utils/apiCalls";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Space,
  notification,
} from "antd";
import { useParams } from "react-router-dom";
import UserHeader from "../../components/userHeader";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import { EditOutlined } from "@ant-design/icons";
import form from "antd/es/form";
import Header from "../../components/header";

const Quiz = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [quiz, setQuiz] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { courseId, moduleId } = useParams();
  const [quizModalVisible, setQuizModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [editingQuiz, setEditingQuiz] = useState(null);

  useEffect(() => {
    loadData(page, 2);
  }, [page]);

  console.log(moduleId);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = `${
        API.GET_QUIZ + Number(moduleId)
      }?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setQuiz(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setQuiz([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  const handleCreateQuiz = () => {
    setQuizModalVisible(true);
  };

  const handleCancelQuiz = () => {
    setEditModalVisible(false);
  };

  const handleCreateCancelQuiz = () => {
    setQuizModalVisible(false);
  };

  const handleCreateQuizSubmit = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);
      const obj = {
        courseId: Number(courseId),
        moduleId: Number(moduleId),
        question: values.question,
        option1: values.option1,
        option2: values.option2,
        option3: values.option3,
        option4: values.option4,
        correctAnswer: values.correctAnswer,
      };
      console.log("Form values", obj);
      const api = API.POST_QUIZ;
      const postRequest: any = await POST(api, obj);
      console.log("API response:", postRequest);
      if (postRequest.status) {
        notification.success({ message: "Quiz created" });
        form.resetFields();
        loadData(page, 2);
      } else {
        notification.error({ message: "Failed to create quiz" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (record: any) => {
    form.setFieldsValue({
      question: record.question,
      option1: record.option1,
      option2: record.option2,
      option3: record.option3,
      option4: record.option4,
      correctAnswer: record.correctAnswer,
    });

    setSelectedQuizId(record.id);
    console.log(record.id);
    setEditingQuiz(record);
    setEditModalVisible(true);
  };

  const handleEditQuizSubmit = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);
      const obj = {
        question: values.question,
        option1: values.option1,
        option2: values.option2,
        option3: values.option3,
        option4: values.option4,
        correctAnswer: values.correctAnswer,
      };

      const api = API.UPDATE_QUIZ + Number(selectedQuizId);
      const updateRequest: any = await PUT(api, obj);
      console.log("API response:", updateRequest);
      if (updateRequest.status) {
        notification.success({ message: "Quiz updated" });
        form.resetFields();
        setEditModalVisible(false); 
        loadData(1, 2); 
      } else {
        notification.error({ message: "Failed to update Quiz" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(quiz);

  return (
    <div>
      <div>
        <div className="headerBox">
          <Header
            header="Quiz"
            btn
            onBtnClick={() => setQuizModalVisible(true)}
          />
        </div>

        <Modal
          title="Create Quiz"
          visible={quizModalVisible}
          onCancel={handleCreateCancelQuiz}
          footer={[
            <Button key="back" onClick={handleCreateCancelQuiz}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleCreateQuizSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="question"
              label="Question"
              rules={[{ required: true, message: "Please enter a question" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option1"
              label="Option 1"
              rules={[{ required: true, message: "Please enter option 1" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option2"
              label="Option 2"
              rules={[{ required: true, message: "Please enter option 2" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option3"
              label="Option 3"
              rules={[{ required: true, message: "Please enter option 3" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option4"
              label="Option 4"
              rules={[{ required: true, message: "Please enter option 4" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="correctAnswer"
              label="Correct Answer"
              rules={[
                {
                  required: true,
                  message: "Please enter correct answer",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const option1 = getFieldValue("option1");
                    const option2 = getFieldValue("option2");
                    const option3 = getFieldValue("option3");
                    const option4 = getFieldValue("option4");

                    if (
                      value === option1 ||
                      value === option2 ||
                      value === option3 ||
                      value === option4
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please select a valid answer")
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit Quiz"
          visible={editModalVisible}
          onCancel={handleCancelQuiz}
          footer={[
            <Button key="back" onClick={handleCancelQuiz}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleEditQuizSubmit}>
              Submit
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="question"
              label="Question"
              rules={[{ required: true, message: "Please enter a question" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option1"
              label="Option 1"
              rules={[{ required: true, message: "Please enter option 1" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option2"
              label="Option 2"
              rules={[{ required: true, message: "Please enter option 2" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option3"
              label="Option 3"
              rules={[{ required: true, message: "Please enter option 3" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="option4"
              label="Option 4"
              rules={[{ required: true, message: "Please enter option 4" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="correctAnswer"
              label="Correct Answer"
              rules={[
                {
                  required: true,
                  message: "Please enter correct answer",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const option1 = getFieldValue("option1");
                    const option2 = getFieldValue("option2");
                    const option3 = getFieldValue("option3");
                    const option4 = getFieldValue("option4");

                    if (
                      value === option1 ||
                      value === option2 ||
                      value === option3 ||
                      value === option4
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please select a valid answer")
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <br />

       

        <div className="scrollable-container">
          {isLoading ? (
            <LoadingBox />
          ) : quiz?.length ? (
            <div className="quiz-container">
              {quiz.map((cat: any) => (
                <Card className="quiz-card" key={cat.id}>
                  <div className="quiz-content">
                    <div className="actions">
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        className="edit-icon"
                        onClick={() => handleEditClick(cat)}
                      >
                        Edit
                      </Button>
                    </div>
                    <p className="question">{cat.question}</p>
                    <div className="options">
                      <p>A: {cat.option1}</p>
                      <p>B: {cat.option2}</p>
                      <p>C: {cat.option3}</p>
                      <p>D: {cat.option4}</p>
                    </div>
                    <p className="correct-answer">
                      Correct Answer: {cat.correctAnswer}
                    </p>
                  </div>
                </Card>
              ))}
              <Pagination
                current={page}
                pageSize={meta?.take}
                total={meta?.itemCount}
                onChange={pagination}
              />
            </div>
          ) : (
            <EmptyBox title={"No quizzes available"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
