import React from "react";
import { Card } from "antd";

import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";

interface CourseCategoryDataTableProps {
  data: any[] | null;
}

function CourseCategoryDataTable(props: CourseCategoryDataTableProps) {
  const { data } = props;
  const navigate = useNavigate();

  return (
    <Card>
      <Row>
        {data?.map((courseCategory: any) => {
          return (
            <Col key={courseCategory?.id} md="3">
              <Card
                // onClick={() =>
                //   navigate(
                //     `/Admin/admin-category/coursecategory/${courseCategory?.id}`
                //   )
                // }
                style={{
                  border: "1px solid #c9c9c9",
                  cursor: "pointer",
                 
                }}
              >
                <div className="">{courseCategory?.type}</div>
               
              </Card>
              <br />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}

export default CourseCategoryDataTable;
