// import React, { useEffect, useState } from "react";
// import { Avatar, Card, Pagination, notification, Button } from "antd";
// import { Col, Row } from "react-bootstrap";
// import { API } from "../../config/api";
// import { GET, POST } from "../../utils/apiCalls";
// import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { IoCheckmarkCircle } from "react-icons/io5";
// import { CiLock } from "react-icons/ci";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import Meta from "antd/es/card/Meta";

// const Modules = () => {
//   const [page, setPage] = useState(1);
//   const [take, setTake] = useState(8);
//   const [meta, setMeta] = useState<any>({});
//   const [modules, setModules] = useState<any[]>([]);
//   const [userProgresses, setUserProgresses] = useState<any>([]);
//   const [progressList, setProgressList] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [enroll, setEnroll] = useState<any>({});
//   const navigate = useNavigate();
//   const { courseId } = useParams();
//   const User: any = useSelector((val: any) => val.User.user);
//   const [showData, setShowData] = useState<any>(null);
//   const [lessons, setLesson] = useState<any[]>([]);
//   const [progressData, setProgressData] = useState<any[]>([]);
//   const [loading2, setLoading2] = useState(false);
//   const [courseData, setCourseData] = useState<any>();
//   const Course: any = useSelector((val: any) => val.Course);

//   useEffect(() => {
//     loadData();
//     getProgress();
//   }, [page]);

//   console.log("modules.......", modules);
//   console.log("----------------------==========", courseData);

//   const loadData = async () => {
//     try {
//       setLoading2(true);
//       const api =
//         API.GET_COURSE_MODULE + courseId + `?order=ASC&page=1&take=50`;
//       const lesson: any = await GET(api, {});
//       if (lesson?.status) {
//         setModules(lesson?.data?.rows);
//         // setCourseData(lesson?.data?.rows);

//         console.log("redux Course ======> ", Course);
//         let lsId = Course?.enrolement?.lessonId;
//         const foundObject = await lesson?.data?.find(
//           (obj: any) => obj?.id === String(lsId)
//         );
//         let newOrder = Number(foundObject?.order);
//         const data = await lesson?.data?.find(
//           (obj: any) => obj?.order === newOrder
//         );
//         console.log("final data ====== > ", data);
//         setShowData(data);
//         setLoading2(false);
//       } else {
//         setModules([]);
//         setLoading2(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading2(false);
//     }
//   };

//   // const getmodule = async () => {
//   //   try {
//   //     const api = API.GET_MODULE_DATA + courseId;

//   //     const response: any = await GET(api, {});

//   //     setModulesData(response);

//   //     console.log("err");
//   //   } catch (err) {
//   //     console.log("object");
//   //   }
//   // };

//   const getProgress = async () => {
//     try {
//       setIsLoading(true);
//       const api = "userprogress/progress";
//       let body = {
//         userId: Number(User?.id),
//         courseId: Number(courseId),
//         type: "module",
//       };
//       const progress: any = await POST(api, body);
//       if (progress?.status) {
//         setUserProgresses(progress?.data);
//         let arr: any = [];
//         progress?.data?.map((mod: any) => {
//           arr.push(String(mod?.moduleId));
//         });
//         setProgressList(arr);
//         setIsLoading(false);
//       } else {
//         setUserProgresses([]);
//         setIsLoading(false);
//       }
//     } catch (error) {
//       setIsLoading(false);
//       console.log(error);
//     }
//   };

//   const pagination = (currentPage: any, pageSize: any) => {
//     setPage(currentPage);
//     loadData();
//   };

//   const onClick = (data: any, id: any) => {
//     setLoading2(true);
//     setModules(data);
//     setLoading2(false);
//     if (progressList.includes(id)) {
//       navigate(`/Auth/lesson/${Number(id)}`);
//     } else {
//       notification.warning({ message: "This module is LOCKED" });
//     }
//   };

//   const generateCertificate = () => {
//     const pdf = new jsPDF();
//     const certificateContent = document.getElementById("certificate-content");
//     if (certificateContent) {
//       const certificateText = certificateContent.innerText;
//       pdf.text(certificateText, 10, 10);
//       pdf.save("certificate.pdf");
//     } else {
//       notification.error({ message: "Certificate content not found" });
//     }
//   };

//   const allModulesUnlocked = modules.length === progressList.length;

//   return (
//     <div>
//       <div className="main-box">
//         <Col md="3">
//           <Card className="stickyBoxs">
//             <div className="">All Lessons</div>
//             {modules?.map((module: any) => {
//               return (
//                 <Card
//                   key={module?.id}
//                   loading={loading2}
//                   onClick={() => onClick(module, module?.id)}
//                   className={`module-card ${
//                     !progressList.includes(String(module?.id)) ? "locked" : ""
//                   }`}
//                 >
//                   <Meta
//                     avatar={
//                       progressList?.includes(String(module?.id)) ? (
//                         <IoCheckmarkCircle size={25} color={"green"} />
//                       ) : (
//                         <CiLock size={25} color={"#000"} />
//                       )
//                     }
//                     title={module?.titleOne}
//                   />
//                 </Card>
//               );
//             })}
//             {allModulesUnlocked && (
//               <Button type="primary" onClick={generateCertificate}>
//                 Complete Course
//               </Button>
//             )}
//           </Card>
//         </Col>
//         {/* Hidden div for certificate content */}
//         <div id="certificate-content" style={{ display: "none" }}>
//           <div className="certificate-container">
//             <div className="certificate-header">
//               <center></center>
//             </div>
//             <div className="certificate-content">
//               <p>This certifies that</p>
//               <h2>{User.name}</h2>
//               <p>has successfully completed the course</p>

//               {Array.from(
//                 new Set(modules.map((cat: any) => cat?.course?.titleOne))
//               ).map((title: string, index: number) => (
//                 <h2 key={index}>{title}</h2>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Modules;
import React, { useEffect, useState } from "react";
import { Avatar, Card, Pagination, notification, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoCheckmarkCircle } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import jsPDF from "jspdf";
import Meta from "antd/es/card/Meta";
import "./styles.scss";
import Logos from "../modules/image/logo.png";

const Modules = () => {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(8);
  const [meta, setMeta] = useState<any>({});
  const [modules, setModules] = useState<any[]>([]);
  const [userProgresses, setUserProgresses] = useState<any>([]);
  const [progressList, setProgressList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enroll, setEnroll] = useState<any>({});
  const navigate = useNavigate();
  const { courseId } = useParams();
  const User: any = useSelector((val: any) => val.User.user);
  const [showData, setShowData] = useState<any>(null);
  const [lessons, setLesson] = useState<any[]>([]);
  const [progressData, setProgressData] = useState<any[]>([]);
  const [loading2, setLoading2] = useState(false);
  const [courseData, setCourseData] = useState<any>();
  const Course: any = useSelector((val: any) => val.Course);

  useEffect(() => {
    loadData();
    getProgress();
  }, [page]);

  console.log("modules.......", modules);
  console.log("----------------------==========", courseData);

  const loadData = async () => {
    try {
      setLoading2(true);
      const api =
        API.GET_COURSE_MODULE + courseId + `?order=ASC&page=1&take=50`;
      const lesson: any = await GET(api, {});
      if (lesson?.status) {
        setModules(lesson?.data?.rows);
        // setCourseData(lesson?.data?.rows);

        console.log("redux Course ======> ", Course);
        let lsId = Course?.enrolement?.lessonId;
        const foundObject = await lesson?.data?.find(
          (obj: any) => obj?.id === String(lsId)
        );
        let newOrder = Number(foundObject?.order);
        const data = await lesson?.data?.find(
          (obj: any) => obj?.order === newOrder
        );
        console.log("final data ====== > ", data);
        setShowData(data);
        setLoading2(false);
      } else {
        setModules([]);
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const getProgress = async () => {
    try {
      setIsLoading(true);
      const api = "userprogress/progress";
      let body = {
        userId: Number(User?.id),
        courseId: Number(courseId),
        type: "module",
      };
      const progress: any = await POST(api, body);
      if (progress?.status) {
        setUserProgresses(progress?.data);
        let arr: any = [];
        progress?.data?.map((mod: any) => {
          arr.push(String(mod?.moduleId));
        });
        setProgressList(arr);
        setIsLoading(false);
      } else {
        setUserProgresses([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData();
  };

  const onClick = (data: any, id: any) => {
    setLoading2(true);
    setModules(data);
    setLoading2(false);
    if (progressList.includes(id)) {
      navigate(`/Auth/lesson/${Number(id)}`);
    } else {
      notification.warning({ message: "This module is LOCKED" });
    }
  };

  // const generateCertificate = () => {
  //   const pdf = new jsPDF();
  //   const certificateContent = document.getElementById("certificate-content");
  //   if (certificateContent) {
  //     pdf.html(certificateContent, {
  //       callback: (doc) => {
  //         doc.save("certificate.pdf");
  //       },
  //     });
  //   } else {
  //     notification.error({ message: "Certificate content not found" });
  //   }
  // };

  const generateCertificate = () => {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 612], // 11in x 8.5in for landscape mode
    });
    const certificateContent = document.getElementById("certificate-content");

    if (certificateContent) {
      pdf.html(certificateContent, {
        callback: (doc) => {
          doc.save("certificate.pdf");
        },
        x: 20, // Adjusting the horizontal margin
        y: 20, // Adjusting the vertical margin
        width: 752, // Width of the content
        windowWidth: 1000, // Width of the browser window for rendering
      });
    } else {
      notification.error({ message: "Certificate content not found" });
    }
  };

  const allModulesUnlocked = modules.length === progressList.length;

  return (
    <div>
      <div className="main-box">
        <Col md="3">
          <Card className="stickyBoxs">
            <div className="">All Lessons</div>
            {modules?.map((module: any) => {
              return (
                <Card
                  key={module?.id}
                  loading={loading2}
                  onClick={() => onClick(module, module?.id)}
                  className={`module-card ${
                    !progressList.includes(String(module?.id)) ? "locked" : ""
                  }`}
                >
                  <Meta
                    avatar={
                      progressList?.includes(String(module?.id)) ? (
                        <IoCheckmarkCircle size={25} color={"green"} />
                      ) : (
                        <CiLock size={25} color={"#000"} />
                      )
                    }
                    title={module?.titleOne}
                  />
                </Card>
              );
            })}
            {allModulesUnlocked && (
              <Button type="primary" onClick={generateCertificate}>
                Complete Course
              </Button>
            )}
          </Card>
        </Col>
        {/* Hidden div for certificate content */}
        <div id="certificate-content">
          <div className="certificate-container">
            <div className="certificate-header">
              <div className="sideBar-logo-toggle">
                <img alt="E-Learn" src={Logos} className="small-logos" />
              </div>
              <br />

              <h1 className="certificate-title">Certificate of Completion</h1>
            </div>
            <div className="certificate-content">
              <p>This certifies that</p>
              <h2>{User.name}</h2>
              <p>has successfully completed the course</p>
              {Array.from(
                new Set(modules.map((cat: any) => cat?.course?.titleOne))
              ).map((title: string, index: number) => (
                <h2 key={index}>{title}</h2>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
