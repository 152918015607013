import React from "react";
import { Card, Row, Col, Progress, Rate } from "antd";
import { useNavigate } from "react-router-dom";

import icon from "./image/icon.jpg";
import "./style.scss";

interface CourseDataTablesProps {
  data: any[] | null;
}

function CourseDataTables(props: CourseDataTablesProps) {
  const { data } = props;
  const navigate = useNavigate();

  const dataTable = () => {
    return (
      <Row gutter={16}>
        {data?.map((course) => (
          <Col key={course.id} md={12} lg={6}>
            <Card
              style={{ cursor: "pointer" }}
              // onClick={() => navigate(`/Auth/course-details/${course?.id}`)}
              title={course.type}
              className="courseCard"
            >
              <div className="card-data">
                <img
                  src={course.image}
                  alt="image"
                  style={{
                    backgroundColor: "red",
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
                <p className="card-titleone">{course.titleOne}</p>
                <p className="card-titletwo">{course.titleTwo}</p>
                <Progress percent={course.progress} />
                Rate Component
                <Rate value={course.rating} disabled />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return <>{dataTable()}</>;
}

export default CourseDataTables;
