import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slice/userSlice";
import { notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { Button, Form, Input } from "antd";
import Logo from "../../assets/images/logo2.svg";
import pic2 from "../../assets/images/pic2.jpg";
import "./styles.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        email: values.user,
        password: values.password,
      };
      let url = API.LOGIN;
      let { data, status, message }: any = await POST(url, obj);
      if (status) {
        setLoading(false);
        notification.success({ message });
        dispatch(login({ ...data }));
        if (data?.type === "Admin") {
          navigate("/Admin");
        } else {
          navigate("/Auth");
        }
      } else {
        setLoading(false);
        notification.error({ message });
      }
    } catch (error) {
      setLoading(false);
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="login-main">
      <Row>
        <Col md={6}>
          <div className="login-image">
            <img src={pic2} alt="qwe" />
          </div>
        </Col>

        <Col md={6}>
          <div className="loginScreen">
            <div className="loginScreen-content">
              <div className="loginScreen-Box3">
                <img src={Logo} alt="Logo" className="loginScreen-logo" />
              </div>
              <div className="loginScreen-txt1">Login to Continue</div>
              <Form onFinish={onFinish} className="loginform">
                <label className="formLabel">Email or Username</label>
                <Form.Item
                  name={"user"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter email or username",
                    },
                  ]}
                >
                  <Input size="large" style={{ width: "100%" }} />
                </Form.Item>
                <label className="formLabel">Password</label>
                <Form.Item
                  name={"password"}
                  rules={[{ required: true, message: "Please enter password" }]}
                >
                  <Input.Password style={{ width: "100%" }} size="large" />
                </Form.Item>
               
                <Button
                  block
                  size="large"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  Sign In
                </Button>
              </Form>
              <br />
              <p className="formLabel">
                Are you new ? <Link to="/signup">Create a new account</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
