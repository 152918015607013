import React from 'react';
import { Button, Popover } from 'antd';
import { RiUserSmileLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/slice/userSlice';
import { FiBell } from 'react-icons/fi';

function LayoutHeader(props: any) {
  const user = useSelector((state:any) => state.User.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleProfileClick = () => {
    navigate('/Auth/profile');
  };

  return (
    <div className="LayoutHeader">
      <div style={{ flex: 1 }}>
        {/* <Button type="primary">Quick Access</Button>
        &nbsp; &nbsp;
        <Button>10-1212 1212</Button> */}
      </div>
      <div className="LayoutHeader-box">
        <FiBell size={25} />
        &nbsp; &nbsp;&nbsp; &nbsp;
        <div>
          <Popover
            placement="bottomLeft"
            content={
              <div>
                <Button onClick={handleLogout}>Logout</Button>
                <br />
                <Button onClick={handleProfileClick}>View Profile</Button>
              </div>
            }
          >
            <RiUserSmileLine fontSize={40} />
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default LayoutHeader;
