import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { signup } from "../../redux/slice/userSlice";
import { notification } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input } from "antd";
import "./styles.scss";
import pic3 from "../../assets/images/pic3.jpg";
import profile from "../../assets/images/profile.avif";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image,setImage]=useState(profile)

  console.log(image);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        name: values.name,
        username: values.name,
        email: values.email,
        password: values.password,
        dob: values.dob,
        phone: values.phone,
        image:image,
      };
      console.log(obj);
      let url = API.SIGNUP;
      let { data, status, message }: any = await POST(url, obj);
      if (status) {
        setLoading(false);
        notification.success({ message });
        dispatch(signup({ ...data }));
        navigate("/");
      } else {
        setLoading(false);
        notification.error({ message });
      }
    } catch (error) {
      setLoading(false);
      console.error("signup failed:", error);
    }
  };

  const validatePhone = (_: any, value: string) => {
    if (value && /[a-zA-Z]/.test(value)) {
      return Promise.reject(new Error("Phone number cannot contain letters"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="login-main">
        <Row>
          <Col md={6}>
            <div className="login-image">
              <img src={pic3} alt="qwe" />
            </div>
          </Col>

          <Col md={6}>
            <div className="loginScreen">
              <div className="loginScreen-Box3"></div>
              <div className="loginScreen-txt1">SignUp and Continue</div>
              <Form onFinish={onFinish} className="login form">
                <label className="formLabel">Name</label>
                <Form.Item
                  name={"name"}
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input size="large" type="name" />
                </Form.Item>
                {/* <br /> */}

                <label className="formLabel">Email</label>
                <Form.Item
                  name={"email"}
                  rules={[{ required: true, message: "Please enter email" }]}
                >
                  <Input size="large" type="email" />
                </Form.Item>
                {/* <br /> */}

                <label className="formLabel">Password</label>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: "Please enter password" },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                {/* <br /> */}

                <label className="formLabel">Confirm Password</label>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  className="confirmPasswordFormItem" 
                >
                  <Input.Password
                    size="large"
                    className="confirmPasswordField" 
                  />
                </Form.Item>
                {/* <br /> */}

                <label className="formLabel">DOB</label>
                <Form.Item
                  name={"dob"}
                  rules={[
                    { required: true, message: "Please enter your dob" },
                  ]}
                >
                  <Input type="date" size="large" />
                </Form.Item>
                {/* <br /> */}

                <label className="formLabel">Phone</label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                    {
                      validator: validatePhone,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="tel"
                    onKeyDown={(e) => {
                      if (e.keyCode !== 8 && e.key.match(/[a-zA-Z]/)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

                <Button
                  block
                  size="large"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  SignUp
                </Button>
              </Form>
              {/* <br /> */}
              <p className="formLabel">
                Already have an account?{" "}
                <span
                  onClick={() => navigate("/")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#007bff",
                  }}
                >
                  Log in
                </span>
              </p>
            </div>
            
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SignUp;
