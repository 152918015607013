import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";
import Home from "../app/home";
import { useSelector } from "react-redux";
import ProtectedRoute from "./protectRouter";
import { Button, Layout, Menu, theme } from "antd";
import SideBar from "./sideBar";
import LayoutHeader from "./layoutHeader";
import { useNavigate } from "react-router-dom";
import Courses from "../admin/courses";
import Users from "../admin/users";
import Category from "../admin/category";
import Enrolements from "../admin/enrolements";
import Settings from "../admin/settings";

import Modulecreation from "../admin/modules";
import LessonCreation from "../admin/lesson";
import ViewUsers from "../admin/users/Viewuser";
import CourseCategory from "../admin/category/coursecategory";
import Quiz from "../admin/quiz";
import "./styles.scss";




const AdminNavigation = () => {
  const User = useSelector((state: any) => state.User);
  const [collapsed, setCollapsed] = useState(false);
  const { Sider, Content } = Layout;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <Layout>
       

        <Sider trigger={null} collapsible collapsed={collapsed}>
          <SideBar
            collapsed={collapsed}
            doCollapse={() => setCollapsed(!collapsed)}
          />
        </Sider>
        <Layout>
          <LayoutHeader doCollapse={() => setCollapsed(!collapsed)} />

          <Content style={{ background: "#f5fffd" }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/admin-dashboard" element={<Dashboard />} />
              <Route path="/admin-courses" element={<Courses />} />
              <Route path="/admin-usres" element={<Users />} />
              <Route path="/admin-category" element={<Category />} />
              <Route path="/admin-enrolements" element={<Enrolements />} />
              <Route path="/admin-settings" element={<Settings />} />
              <Route path="/admin-usres/Viewuser/:id" element={<ViewUsers />} />
              <Route
                path="/admin-category/coursecategory/:id"
                element={<CourseCategory />}
              />
              <Route
                path="/admin-quiz/:courseId/:moduleId"
                element={<Quiz />}
              />

              <Route
                path="/admin-addModules/:courseId"
                element={<Modulecreation />}
              />
              <Route
                path="/admin-addLesson/:courseId/:moduleId"
                element={<LessonCreation />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminNavigation;
