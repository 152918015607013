import React from "react";
import { Modal, Form, Input, Button, notification } from "antd";

const CreateModel = (props: any) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      await props.handleFormSubmit(values); // Call handleFormSubmit function from props
    } catch (error) {
      console.error("Form submission failed:", error);
      notification.error({ message: "Failed to submit form" });
    }
  };

  const onCancel = () => {
    form.resetFields();
    props.close(); // Close the modal
  };

  return (
    <Modal
      title="Create New Lesson"
      visible={props.open}
      onCancel={onCancel}
      footer={[
        <Button size="large" key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button size="large" key="submit" type="primary" onClick={form.submit}>
          Submit
        </Button>,
      ]}
      width={800}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {/* <Form.Item
          name="lessonId"
          label="Lesson Id"
          rules={[{ required: true, message: "Please enter the Lesson Id" }]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter the lesson title" }]}
        >
          <Input size="large" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Please enter the lesson description" },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="syntax"
          label="Syntax"
          rules={[
            { required: true, message: "Please enter the lesson syntax" },
          ]}
        >
          <Input.TextArea rows={10} />
        </Form.Item>
        <Form.Item
          name="video"
          label="video"
          rules={[{ required: true, message: "Please enter the video id" }]}
        >
          <Input size="large" style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateModel;
