


import React, { useState } from "react";
import { Table, Button, Modal, Input, Form, notification } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons"; 
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api"; 
import { PUT } from "../../utils/apiCalls"; 

interface ModuleDataTableProps {
  data: any[] | null;
  loadData: () => void; 
}

interface Module {
  id: string;
  courseId: string;
  titleOne: string;
  titleTwo: string;
  description: string;
}


function ModuleDataTable(props: ModuleDataTableProps) {
  const { data, loadData } = props;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [form] = Form.useForm();

  const showEditModal = (module: Module) => {
    setSelectedModule(module);
    form.setFieldsValue(module);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (selectedModule?.id) {
        const api = `${API.UPDATE_MODULE}${selectedModule.id}`;
        setIsLoading2(false);
        const updateRequest: any = await PUT(api, values);
        console.log("API response:", updateRequest);

        if (updateRequest.status) {
          notification.success({ message: "Module updated" });
          form.resetFields();
          setIsModalVisible(false);
          loadData();
        } else {
          notification.error({ message: "Failed to update module" });
        }
      } else {
        notification.error({ message: "Module ID is missing" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading2(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showViewModal = (module: Module) => {
    Modal.info({
      title: 'View Module',
      content: (
        <div>
          <p><strong>Title One:</strong> {module.titleOne}</p>
          <p><strong>Title Two:</strong> {module.titleTwo}</p>
          <p><strong>Description:</strong> {module.description}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'titleOne',
      key: 'titleOne',
      render: (text: string, record: Module) => (
        <a
          onClick={() => navigate(`/Admin/admin-addLesson/${record.courseId}/${record.id}`)}
          style={{ color: 'Blue' }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: Module) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={() => showViewModal(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
          />
        </>
      ),
    },
  ];

  const dataSource = Array.isArray(data) ? data : [];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
      />
      <Modal
        title="Edit Module"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        confirmLoading={isLoading}
      >
        {selectedModule && (
          <Form form={form} layout="vertical">
            <Form.Item
              label="Title One"
              name="titleOne"
              rules={[{ required: true, message: 'Please input the title one!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Title Two"
              name="titleTwo"
              rules={[{ required: true, message: 'Please input the title two!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input the description!' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default ModuleDataTable;





