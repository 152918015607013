import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./menu.json";
import "./styles.scss";
import DynamicIcon from "./dymanicIcon";
import Logo from "../assets/images/semikolen-logo.svg";
import { MdMenuOpen, MdOutlineMenu } from "react-icons/md";
import { useState } from "react";
import Logos from "../assets/images/Semikolon Round Logo - blue.png";

function SideBar(props: any) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  const handleToggle = () => {
    setToggle(!toggle);
    props.doCollapse(!toggle);
  };

  return (
    <div className="sideBar-Box" >
      <div className="toggleButton">
        {props?.collapsed ? (
          <MdOutlineMenu size={30} color="#fff" onClick={handleToggle} />
        ) : (
          <MdMenuOpen size={30} color="#fff" onClick={handleToggle} />
        )}
      </div>
      {!toggle && (
        <div className="sideBar-logo-toggle">
        <img alt="E-Learn" src={Logo} className="large-logo" />
      </div>
      )}
      {toggle && (
        <div className="sideBar-logo-toggle">
          <img alt="E-Learn" src={Logos} className="small-logo" />
        </div>
      )}
      <br />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
        {Menu?.slice(0, 7).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              {!props?.collapsed ? (
                <>
                  <div style={{ marginLeft: 20 }} />
                  <div>{item?.title}</div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
