import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Card,
  Upload,
  Select,
  Row,
  Col,
  Pagination,
} from "antd";
import Header from "../../components/header";
import { API } from "../../config/api";
import { FILE_UPLOAD, GET, POST } from "../../utils/apiCalls";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import { CiSearch } from "react-icons/ci";
import CourseDataTables from "./datatable";

const Courses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [courseData, setCourseData] = useState<any>(null);

  const [form] = Form.useForm();
  const [file, setFile] = useState<any>([]);
  const [courseCategoryData, setCourseCategoryData] = useState<any[]>([]);

  const [searchResultCount, setSearchResultCount] = useState(0);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [isLoading2, setIsLoading2] = useState(false);


  useEffect(() => {
    load(page, 5);
    category();
  }, [page]);

  const navigate = useNavigate();

  const load = async (page: number, take: number) => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSES + `?order=ASC&page=${page}&take=${take}`;
      const course: any = await GET(url, {});
      console.log("course ", course);
      setCourseData(course?.data || []);
      console.log("end");
      setMeta({
        itemCount: course.meta.itemCount,
        take: take,
      });
    } catch (error) {
      console.error("Error while loading data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const category = async () => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSE_CATEGORY + `?order=ASC&page=1&take=50`;
      const courseCategory: any = await GET(url, {});
      console.log("courseCategory ", courseCategory);
      setCourseCategoryData(courseCategory?.data || []);
      console.log("--------", courseCategoryData)
      setMeta({
        itemCount: courseCategory.meta.itemCount,

      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };



  const search = async (query: string) => {
    setIsLoading2(true);
    try {
      let url = API.SEARCH_COURSES;
      let obj = { titleOne: query };
      const response: any = await POST(url, obj);
      setIsLoading2(false);
      if (response?.status) {
        setCourseData(response?.data);
        setSearchResultCount(response?.meta.itemCount);
      } else {
        setCourseData([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading2(true);
    }
  };

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);
      const obj = {
        titleOne: values.titleOne,
        titleTwo: values.titleTwo,
        description: values.description,
        category: values.categoryId,
        courseId: values.courseId,
        image: file,
      };
      console.log("Form values:", obj);
      const api = API.POST_COURSES;
      const postRequest: any = await POST(api, obj);
      console.log("API response:", postRequest);
      if (postRequest.status) {
        notification.success({ message: "Course created" });
        form.resetFields();
        setModalVisible(false);
        load(page, 5);
      } else {
        notification.error({ message: "Failed to create course" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const pagination = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    load(currentPage, pageSize);
  };

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  return (
    <div className="main-box">
      <div className="headerBox">
        <Header header="Courses" btn onBtnClick={() => setModalVisible(true)} />
        <br />

        <Card>
          {isLoading ? (
            <LoadingBox />
          ) : courseData && courseData.length > 0 ? (
            <>
              <h4 className="">
                Showing {courseData?.length} of {meta?.itemCount} Courses
              </h4>
              <br />
              <Input
                bordered
                style={{ width: "300px" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  search(e.target.value)
                }
                suffix={<CiSearch size={16} color="#000" />}
                placeholder="Search for Courses"
              />
              <br />
              <br />

              <CourseDataTables
                data={courseData}
                loadData={function () {
                  throw new Error("Function not implemented.");
                }}
              />
            </>
          ) : (
            <EmptyBox title="Courses" />
          )}
          <br />

          <Modal
            title="Create New Course"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={[
              <Button
                size="large"
                key="cancel"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>,
              <Button
                size="large"
                key="submit"
                type="primary"
                onClick={handleFormSubmit}
                loading={isLoading}
              >
                Submit
              </Button>,
            ]}
            width={800}
          >
            <Form form={form} layout="vertical">
              <label className="formLabel">Title One</label>
              <Form.Item
                name="titleOne"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Please enter title one" }]}
              >
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Title One"
                />
              </Form.Item>
              <label className="formLabel">Title Two</label>
              <Form.Item
                name="titleTwo"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Please enter title two" }]}
              >
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Title Two"
                />
              </Form.Item>
              <Form.Item
                label="Select Category"
                name="categoryId"
                rules={[
                  { required: true, message: "Please select a category" },
                ]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Select Category"
                >
                  {courseCategoryData.map((category: any) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <label className="formLabel">Description</label>
              <Form.Item
                name="description"
                rules={[
                  { required: true, message: "Please enter description" },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder="Description"
                  rows={4}
                />
              </Form.Item>
              <label className="formLabel">Image</label>
              <Form.Item
                name="file"
                rules={[{ required: true, message: "Please upload an image" }]}
              >
                <Upload onChange={UploadFile}>
                  <div
                    style={{
                      marginLeft: 10,
                      width: "100%",
                    }}
                  >
                    <UploadOutlined /> Click to Upload
                  </div>
                </Upload>
              </Form.Item>
            </Form>
          </Modal>
          <br />
          <div className="paginationBox">
            <Pagination
              current={page}
              pageSize={meta.take}
              total={meta.itemCount}
              onChange={pagination}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Courses;
