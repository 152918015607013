

import React, { useEffect, useState } from "react";
import { Card, Pagination, Progress, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import "./styles.scss";
import UserHeader from "../../components/userHeader";
import { useSelector } from "react-redux";
import { CgLayoutGrid } from "react-icons/cg";

const Enrolement = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [enrollment, setEnrollment] = useState([]);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const User: any = useSelector((val: any) => val.User.user);


  useEffect(() => {
    loadData(page,6);
  }, [page]);

  const loadData = async (page : any, take :any) => {
    setIsLoading(true);
    try {
      let url = `${API.GET_USER_ENROLLEMENT + Number(User.id)}?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setEnrollment(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setEnrollment([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  console.log(enrollment);

  return (
    <div className="main-box">
      <div className="headerBox">
        <UserHeader title="Enrollments" count={enrollment?.length} />
      </div>
      <br />
      <div className="main-box">
        {isLoading ? (
          <LoadingBox />
        ) : enrollment?.length ? (
          <div className="">
            <Card style={{ height: "100vh" }}>
              <Row>
                {enrollment?.map((cat: any) => {
                  return (
                    <Col md="3">
                      <Card className="cat-card">
                        <div className="txt1">{cat?.course?.titleOne}</div>

                      </Card>

                      <br />
                    </Col>
                  );
                })}
              </Row>
              <br />
              <Pagination
                current={page}
                pageSize={meta?.take}
                total={meta?.itemCount}
                onChange={pagination}
              />
            </Card>
          </div>
        ) : (
          <EmptyBox title={"Enrollments"} />
        )}
      </div>
    </div>
  );
};

export default Enrolement;


