import { Table } from "antd";
import moment from "moment";
import React from "react";

function DataTable({ dataSource }: any) {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      render: (record: number) => (
        <div className="table-Txt">{moment(record).format("ll")}</div>
      ),
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 100 }}
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
}

export default DataTable;
