import React, { useEffect, useState } from "react";
import { Card, Input, Pagination, Progress, Rate, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import "./styles.scss";
import UserHeader from "../../components/userHeader";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import CourseDataTables from "../../components/coursedesigin";

const Course = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);

  useEffect(() => {
    // loadData(page, 8);
    loadCourse();
  }, [page]);

  const loadCourse = async () => {
    try {
      let api = "course/list";
      let obj = {
        page: 1,
        take: 30,
      };
      const course: any = await POST(api, obj);
      if (course?.status) {
        setCourses(course?.data);
        setMeta(course?.meta);
        setIsLoading(false);
      } else {
        setCourses([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      notification.error({ message: "Network error" });
    }
  };

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSES + `?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setCourses(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setCourses([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const search = async (query: string) => {
    setIsLoading(true);
    try {
      let url = API.SEARCH_COURSES;
      let obj = { titleOne: query };

      const response: any = await POST(url, obj);
      if (response?.status) {
        setSearchResult(response?.data);
        setSearchResultCount(response?.meta);
        setIsLoading(true);
      } else {
        setSearchResult([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  return (
    <div className="main-box">
      <div className="headerBox">
        <UserHeader title="Courses" count={courses?.length} />
      </div>
      <br />
      <Card>
        <Input
          bordered={false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            search(e.target.value)
          }
          suffix={<CiSearch size={16} color="#000" />}
          placeholder="Search for courses"
          className="search-input"
        />

        <div className="overFlow-Box2">
          {isLoading && searchResultCount > 0 && (
            <>
              <p>Search results: {searchResultCount}</p>
            </>
          )}
          <CourseDataTables data={searchResult} />

          <Row gutter={16} className="card-container"></Row>
        </div>
      </Card>

      <div className="main-box">
        {isLoading ? (
          <LoadingBox />
        ) : courses?.length ? (
          <div className="container-fluid">
            <Card style={{ minHeight: "100vh" }}>
              <Row>
                {courses?.map((cat: any) => {
                  return (
                    <Col md="3">
                      <Card
                        className="cat-card"
                        onClick={() => navigate(`/Auth/details/${cat.id}`)}
                      >
                        <div className="txt1">
                          <img
                            src={cat.image}
                            alt="image"
                            style={{
                              backgroundColor: "red",
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <p className="card-titleone">{cat.titleOne}</p>
                        <Progress
                          className=" progress-text"
                          percent={cat.score}
                        />
                        {/* <p className="card-titletwo">{cat.titleTwo}</p> */}
                      </Card>
                      <br />
                    </Col>
                  );
                })}
              </Row>
              <br />
              <Pagination
                current={page}
                pageSize={meta.take}
                total={meta.itemCount}
                onChange={pagination}
              />
            </Card>
          </div>
        ) : (
          <EmptyBox title={"Courses"} />
        )}
      </div>
    </div>
  );
};

export default Course;
