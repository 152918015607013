import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import ThemeObj from "./theme";

import ProtectedRoute from "./navigation/protectRouter";
import { useSelector } from "react-redux";
import Login from "./website/login";


import "./App.scss";
import SignUp from "./website/signup";

import AdminNavigation from "./adminNavigation";
import Navigation from "./navigation";
// import UpdatePasswordForm from "./app/profile/UpdatePasswordForm";




function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <ConfigProvider theme={ThemeObj}>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/signup/" element={<SignUp />} />
        {/* <Route path="/Auth/updatepasswordForm" element={<UpdatePasswordForm />} /> */}

        <Route
          path="/Admin/*"
          element={
            <ProtectedRoute isSignedIn={User.admin && User.auth}>
              <AdminNavigation />
            </ProtectedRoute>
          }
        />


        <Route
          path="/Auth/*"
          element={
            <ProtectedRoute isSignedIn={User.auth && !User.admin}>
              <Navigation />
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/update-password" // Add this route for the UpdatePasswordPage
          element={<UpdatePasswordPage />}
        /> */}
      </Routes>
    </ConfigProvider>
  );
}

export default App;
