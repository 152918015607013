import {  Card, Col, Pagination, Progress, Row, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../config/api";
import category from ".";
import { GET, POST } from "../../utils/apiCalls";
import CourseDataTables from "../../components/coursedesigin";
import {Button} from "react-bootstrap";
import UserHeader from "../../components/userHeader";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import Header from "../../components/header";

function CourseCategory() {
  const navigate = useNavigate();
  const [courseCategoryData, setCourseCategoryData] = useState<any>([]);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData(page, 6);
  }, [page]);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = `${API.GET_COURSE_LIST + id}?order=ASC&page=${page}&take=${take}`;
      const response: any = await POST(url, {});
      if (response?.status) {
        setCourseCategoryData(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setCourseCategoryData([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  const handlenavigate =()=>{
    navigate(-1)
   }
  

  return (
      <div className="screen-box">
      <div className="headerBox">
        <UserHeader title="Courses" count={courseCategoryData?.length} />
     </div>
      <br />

      <div className="main-box">
        {isLoading ? (
          <LoadingBox />
        ) : courseCategoryData?.length ? (
          <div className="container">
            <Card style={{ height: "100vh" }}>
              <Row>
                {courseCategoryData?.map((cat: any) => {
                  return (
                    <Col md="3">
                      <Card className="cat-card">
                        <div className="txt1">

                        <img
                          src={cat.image}
                          alt="image"
                          style={{
                            backgroundColor: "red",
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                                       </div>

                        
                        <p className="card-titleone">{cat?.titleOne}</p>

                        <Progress
                        className=" progress-text"
                        percent={cat.progress}
                      />
                        <p className="card-titleone">{cat?.titleTwo}</p>

                        

                      </Card>
                      <br />
                    </Col>
                  );
                })}
              </Row>
              <br />
              <Pagination
                current={page}
                pageSize={1}
                total={1}
                onChange={pagination}
              />
            </Card>
          </div>
        ) : (
          <EmptyBox title={"Courses"} />
        )}
      </div>
    </div>
  
      


    
  );
}

export default CourseCategory;