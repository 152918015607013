// import React, { useState, useEffect } from "react";
// import { Button, Card, Col, Form, Row, Upload } from "antd";
// import "./styles.scss";
// import profile from "../../assets/images/profile.avif";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import { FILE_UPLOAD, GET, PUT } from "../../utils/apiCalls";
// import { API } from "../../config/api";
// import { useDispatch, useSelector } from "react-redux";
// import { login } from "../../redux/slice/userSlice";
// import moment from "moment";
// import { UploadOutlined } from "@ant-design/icons";

// const Profile = () => {
//   const [user, setUser] = useState({
//     name: "",
//     email: "",
//     password: "",
//     dob: "",
//     phone: "",
//     image: "",
//   });
//   const [editName, setEditName] = useState(false);
//   const [editEmail, setEditEmail] = useState(false);
//   const [editPassword, setEditPassword] = useState(false);
//   const [editDob, setEditDob] = useState(false);
//   const [editPhone, setEditPhone] = useState(false);
//   const [EditImage, setEditImage] = useState(false);
//   const [file, setFile] = useState<any>(null);




//   const User = useSelector((val: any) => val.User.user);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const api = API.GET + Number(User.id);
//         const response: any = await GET(api, {});
//         dispatch(updateUser(response));

//         setUser(response);
//       } catch (err) {
//         console.log(err);
//       }
//     };

//     fetchData();
//   }, [User.id, dispatch]);

//   const handleSaveName = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { name: user.name });
//       dispatch(login(data.data));

//       setEditName(false);
//     } catch (error) {
//       console.error("Error updating name:", error);
//     }
//   };

//   const handleSaveEmail = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { email: user.email });
//       dispatch(login(data.data));

//       setEditEmail(false);
//     } catch (error) {
//       console.error("Error updating email:", error);
//     }
//   };

//   const handleSavePassword = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { password: user.password });
//       dispatch(login(data.data));

//       setEditPassword(false);
//     } catch (error) {
//       console.error("Error updating password:", error);
//     }
//   };

//   const handleSaveDob = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { dob: user.dob });
//       dispatch(login(data.data));

//       setEditDob(false);
//     } catch (error) {
//       console.error("Error updating dob:", error);
//     }
//   };

//   const handleSavePhone = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { phone: user.phone });
//       dispatch(login(data.data));

//       setEditPhone(false);
//     } catch (error) {
//       console.error("Error updating phone:", error);
//     }
//   };

//   const handleSaveImage = async () => {
//     try {
//       const api = API.UPDATE + Number(User.id);
//       let data: any = await PUT(api, { image: user.image });
//       dispatch(login(data.data));

//       setEditImage(false);
//     } catch (error) {
//       console.error("Error updating image:", error);
//     }
//   };

//   const UploadFile = async (data: any) => {
//     try {
//       console.log(data);
//       const myFile = data.file;
//       let file = myFile.originFileObj;
//       const name = moment(new Date()).unix();
//       const myNewFile = new File([file], name + ".png", {
//         type: myFile.type,
//       });
//       const url = URL.createObjectURL(myNewFile);
//       let _upload = await FILE_UPLOAD(myNewFile);
//       console.log("upload --> ", _upload);
//       await handleSaveImage(); 
//       setFile(_upload);
//     } catch (err) {
//       console.log("UploadFile = = = >", err);
//     }
//   };

//   return (
//     <div className="screen-box">
//       <div className="main-box">
//     <Card>
//         <Row>
//         <Col md={6}>
//           <div className="profile-container">
//             <div className="profile-row">
//               <div className="profile-section">
//                 <div className="left-content">
//                 <img
//                         src={user.image ? user.image : profile}
//                         alt="profile"
//                         className="profile-image"
//                       />
//                   {/* <img src={profile} alt="image" className="profile-image" /> */}
//                 </div>
//                 <Form.Item name={"file"}>
//                       <Upload onChange={(data) => UploadFile(data)}>
//                         <div style={{ marginLeft: 10, width: "100%" }}>
//                           <UploadOutlined color="red" /> Click to Upload
//                         </div>
//                       </Upload>
//                     </Form.Item>

              
//               </div>
              
//               <div className="profile-section">
//                 <div className="left-content">
//                   <div>
//                   Name:{" "}
//                   {editName ? (
//                     <>
//                       <input
//                         type="text"
//                         value={user.name}
//                         onChange={(e) =>
//                           setUser({ ...user, name: e.target.value })
//                         }
//                       />
//                       <Button onClick={handleSaveName}>Save</Button>
//                     </>
//                   ) : (
//                     <span>{User.name}</span>
//                   )}
//                   </div>
                
//                   <div
//                     className="edit-container"
//                     onClick={() => setEditName(true)}
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                     <span>Edit</span>
//                   </div>
//                 </div>
              
//               </div>
//               <div className="profile-section">
//                 <div className="left-content">
//                   Email:{" "}
//                   {editEmail ? (
//                     <>
//                       <input
//                         type="text"
//                         value={user.email}
//                         onChange={(e) =>
//                           setUser({ ...user, email: e.target.value })
//                         }
//                       />
//                       <Button onClick={handleSaveEmail}>Save</Button>
//                     </>
//                   ) : (
//                     <span>{User.email}</span>
//                   )}
//                 </div>
//                 <div className="right-content">
//                   <div
//                     className="edit-container"
//                     onClick={() => setEditEmail(true)}
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                     <span>Edit</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="profile-section">
//                 <div className="left-content">
//                   Password:{" "}
//                   {editPassword ? (
//                     <>
//                       <input
//                         type="password"
//                         value={user.password}
//                         onChange={(e) =>
//                           setUser({ ...user, password: e.target.value })
//                         }
//                       />
//                       <Button onClick={handleSavePassword}>Save</Button>
//                     </>
//                   ) : (
//                     <span></span>
//                   )}
//                 </div>
//                 <div className="right-content">
//                   <div
//                     className="edit-container"
//                     onClick={() => setEditPassword(true)}
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                     <span>Edit</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="profile-section">
//                 <div className="left-content">
//                   Date of Birth:{" "}
//                   {editDob ? (
//                     <>
//                       <input
//                         type="text"
//                         value={user.dob}
//                         onChange={(e) =>
//                           setUser({ ...user, dob: e.target.value })
//                         }
//                       />
//                       <Button onClick={handleSaveDob}>Save</Button>
//                     </>
//                   ) : (
//                     <span>{User.dob}</span>
//                   )}
//                 </div>
//                 <div className="right-content">
//                   <div
//                     className="edit-container"
//                     onClick={() => setEditDob(true)}
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                     <span>Edit</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="profile-section">
//                 <div className="left-content">
//                   Phone:{" "}
//                   {editPhone ? (
//                     <>
//                       <input
//                         type="text"
//                         value={user.phone}
//                         onChange={(e) =>
//                           setUser({ ...user, phone: e.target.value })
//                         }
//                       />
//                       <Button onClick={handleSavePhone}>Save</Button>
//                     </>
//                   ) : (
//                     <span>{User.phone}</span>
//                   )}
//                 </div>
//                 <div className="right-content">
//                   <div
//                     className="edit-container"
//                     onClick={() => setEditPhone(true)}
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                     <span>Edit</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Col>
//       </Row>
      
//     </Card>
//     </div>
//     </div>
//   );
// };

// export default Profile;

// function updateUser(response: any): any {
//   throw new Error("Function not implemented.");
// }


import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Upload,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { FILE_UPLOAD, GET, PUT } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import { API } from "../../config/api";

interface User {
 
  id: string;
  name: string;
  email: string;
  dob: string;
  registrationDate: string;
  phone: string;
  image:string
}

function Profile() {
  const [file, setFile] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [upload, setUpload] = useState("");
  const User = useSelector((val: any) => val.User.user);
  const [userData, setUserData] = useState<User | null>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        name: userData?.name,
        email: userData?.email,
        dob: userData?.dob ? dayjs(userData.dob) : null,
      
        phone: userData?.phone,
      });
    }
  }, [userData, form]);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const url = API.GET + User.id;
      const { data }: any = await GET(url, null);
      setUserData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const UploadFile = async (data: any) => {
    try {
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = dayjs().unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  const submitHandler = async (values: any) => {
    try {
      let obj = {
        name: values.name,
        email: values.email,
        dob: values.dob.format("YYYY-MM-DD"),
        phone: values.phone,
        password: values.password,
        image: String(file)
      };
      setIsLoading2(true);
      let api = API.UPDATE + User.id;
      let postRequest: any = await PUT(api, obj);
      if (postRequest.status) {
        notification.success({ message: "User Updated" });
      } else {
        notification.error({ message: "Failed to Update" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <Container>
      <Form form={form} onFinish={submitHandler}>
        <Card>
          <Row>
            <Col md="6">
              <img
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "100%",
                  marginBottom: "30px",
                }}
                src={userData?.image}
              />
              <Form.Item name={"file"} rules={[{ required: false }]}>
                <Upload onChange={(data) => UploadFile(data)}>
                  <div
                    style={{
                      marginLeft: 10,
                      width: "100%",
                    }}
                  >
                    <EditOutlined /> Edit Photo
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col md="6" />
            <br />
            <br />
            <Col md="6">
              <label className="formLabel">Name</label>
              <Form.Item
                name="name"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Email</label>
              <Form.Item
                name="email"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">DOB</label>
              <Form.Item
                name="dob"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Phone Number</label>
              <Form.Item
                name="phone"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel"> Password</label>
              <Form.Item
                name="password"
                style={{ marginBottom: 10 }}
                rules={[{ required: false }]}
              >
                <Input.Password
                  size="large"
                  placeholder=""
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default Profile;
