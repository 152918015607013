import { PiDotsSixThin } from "react-icons/pi";

export const API = {
  // BASE_URL: "http://localhost:8008/",
  BASE_URL: "https://server.bairuhatech.com/elearning-server/",

  //Login
  LOGIN: "users/userLogin",

  //signup
  SIGNUP: "users/",

  //profile
  UPDATE_PASSWORD: "users/updatePassword/",

  //block
  BLOCK_USER: "users/block/",
  UNBLOCK_USER: "users/unblock/",

  //dashboard
  GET_COUNT: "course/dashboard",

  //CATEGORIES
  GET_COURSE_CATEGORY: "courseCategory",
  GET_COURSE_LIST: "course/getByCategory/",
  UPDATE_COURSE_CATOGORY: "courseCategory/",

  // ADMIN_CATEGORY
  POST_CATEGORY: "courseCategory/createCourseCategory",

  //COURSES
  GET_COURSES: "course/get",
  GET_COURSE_DETAILS: "course/",
  UPDATE_COURSE: "course/",

  //Enrollments
  GET_ENROLLMENTS: "Enrollments",
  CREATE_ENROLLMENTS: "enrollments",
  UPDATE_ENROLLMENTS: "Enrollments/",
  UPDATE_MODULE_ENROLLMENTS: "Enrollments/updateByModuleId/",

  // ENROLL_COURSE: "Enrollments",
  SEARCH_COURSES: "course/search",

  //Module
  GET_COURSE_MODULE: "module/getByCourseId/",
  UPDATE_MODULE: "module/",

  //Lesson
  GET_COURSE_LESSON: "lesson/getByModuleId/",
  UPDATE: "users/",
  GET: "users/",
  UPDATE_LESSON: "lesson/updateLesson/",

  GET_DATA_LESSON: "lesson/",

  //User Progress
  POST_USER_PROGRESS: "userprogress/create",
  GET_USER_PROGRESS: "userprogress/module/",
  USER_PROGRESS: "userprogress/progress/",

  //Admin Creation
  POST_COURSES: "course/createCourse",
  POST_MODULES: "module/moduleCreation",
  POST_LESSON: "lesson/createLesson",

  //Enrollment count
  GET_COUNT_ENROLLEMENT: "users/enrollment/count/",
  GET_USER_ENROLLEMENT: "Enrollments/getByUserId/",
  LISTENROLLMENT: "Enrollments/list",

  //quiz
  POST_QUIZ: "quiz",
  GET_QUIZ: "quiz/getByModuleId/",
  UPDATE_QUIZ: "quiz/putById/",
  SEARCH_USER: "users/search",
  SEARCH_CATEGORY: "courseCategory/search",

  GET_MODULE_DATA: "module/getModule/",
};
//GET_COURSE_LIST: "course/getByCategory/",lesson/getlessoncount/4
