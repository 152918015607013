import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import DataTable from "./Table";
import { Card, Col, Input, Pagination, Row, notification } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [searchResultCount, setSearchResultCount] = useState(0);

  const getUser = async (page: number, take: number) => {
    try {
      setIsLoading(true);
      let api = API.GET + `?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(api, {});
      setUsers(response?.data);
      setMeta({
        itemCount: response.meta.itemCount,
        take: take,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const search = async (query: string) => {
    setIsLoading2(true);
    try {
      let url = API.SEARCH_USER;
      let obj = { name: query };
      const response: any = await POST(url, obj);
      setIsLoading2(false);
      if (response?.status) {
        setUsers(response?.data);
        setSearchResultCount(response?.meta.itemCount);
      } else {
        setUsers([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading2(true);
    }
  };

  const pagination = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    getUser(currentPage, pageSize);
  };

  useEffect(() => {
    getUser(page, 10);
  }, [page]);

  const navigate = useNavigate();

  return (
    <div className="main-box">
      <div className="headerBox">
        <Header header="Users" />
      </div>
      <br />
      <Card>
        {isLoading ? (
          <LoadingBox />
        ) : users.length ? (
          <>
        
           <h4 className="">
              Showing {users?.length} of {meta?.itemCount} users
            </h4>
            <br />
            <Input
              bordered
              style={{ width: "300px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                search(e.target.value)
              }
              suffix={<CiSearch size={16} color="#000" />}
              placeholder="Search for users"
            />
            <br />
            <br />
            <DataTable dataSource={users} pagination={false} />
          </>
        ) : (
          <EmptyBox />
        )}

        <br />
        <div className="paginationBox">
          <Pagination
            current={page}
            pageSize={meta.take}
            total={meta.itemCount}
            onChange={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default Users;
