import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const UserHeader = (props: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col md="1">
          <div className="headerBackBtn" onClick={() => navigate("/Auth/")}>
            <IoArrowBackOutline color="grey" size={40} />
          </div>
        </Col>
        <Col md="3">
          <div className="headerHeadBox">
            <div className="headerTxt1">
              {props?.title} ( {props?.count} )
            </div>
          </div>
        </Col>
        <Col md="6"></Col>
      </Row>
    </div>
  );
};

export default UserHeader;
