import { Button, Popover } from "antd";
import { RiUserSmileLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slice/userSlice";
import { FiBell } from "react-icons/fi";

function LayoutHeader(props: any) {
  const User: any = useSelector((val: any) => val.User.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="LayoutHeader">
      <div style={{ flex: 1 }}>
       
      </div>
      <div className="LayoutHeader-box">
        &nbsp; &nbsp;&nbsp; &nbsp;
        <div>
          <Popover
            placement="bottomLeft"
            content={
              <div onClick={() => navigate("/Auth/profile")}>
                <button>Log out</button>
              </div>
            }
          >
            <RiUserSmileLine fontSize={40} />
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default LayoutHeader;
