import { Button, Card } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButtonClick = () => {
    if (location.pathname === "/Admin/admin-enrolements") {
      navigate("/Admin");
    } else if (location.pathname === "/Admin/admin-courses") {
      navigate("/Admin");
      navigate("/Admin/admin-dashboard");
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="header">
      <div className="headerBox">
        <Row>
          <Col md="1">
            <div className="headerBackBtn" onClick={handleBackButtonClick}>
              <IoArrowBackOutline color="grey" size={40} />
            </div>
          </Col>
          <Col md="3">
            <div className="headerHeadBox">
              <div className="headerTxt1">{props?.header}</div>
              <div className="headerTxt2">{props.header2}</div>
            </div>
          </Col>

          <Col md="6"></Col>
          <Col md="2">
            {props?.btn ? (
              <div className="headerButton" onClick={props.onBtnClick}>
                Create
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
