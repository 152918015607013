import React, { useEffect ,useState} from "react";
import { Modal, Form, Input, Button, notification } from "antd";

interface EditModelProps {
  open: boolean;
  data: any;
  handleEditLesson: (values: any) => void;
  close: () => void;
}

const EditModel: React.FC<EditModelProps> = ({
  open,
  data,
  handleEditLesson,
  close,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue({
        title: data.title,
        description: data.description,
        syntax: data.syntax,
        video:data.video,
        // order:data.order,
       
      });
    }
  }, [open, data, form]);

 

  const onFinish = async (values: any) => {
    try {
      await handleEditLesson(values); 
    } catch (error) {
      console.error("Form submission failed:", error);
      notification.error({ message: "Failed to submit form" });
    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  return (
    <div>
      <Modal
        title="Update Lesson"
        visible={open}
        onCancel={close}
        footer={[
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Submit
          </Button>
                  

          

        ]}
        width={1000}

        
      >
       

       
        <Form form={form} onFinish={onFinish}>
          <label className="formLabel">Title</label>
          <Form.Item
            name="title"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Please enter title" }]}
          >
            <Input size="large" placeholder="Title" />
          </Form.Item>

          <label className="formLabel">Video</label>
          <Form.Item
            name="video"
            rules={[{ required: true, message: "Please enter video" }]}
          >
            <Input size="large" placeholder="video" />
          </Form.Item>

          <label className="formLabel">Description</label>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input.TextArea size="large" placeholder="Description" rows={4} />
          </Form.Item>
           
          <label className="formLabel">Syntax</label>
          <Form.Item
            name="syntax"
            rules={[{ required: true, message: "Please enter syntax" }]}
          >
            <Input.TextArea size="large" placeholder="syntax" rows={4} />
          </Form.Item>
          
          {/* <label className="formLabel">Order</label>

          <Form.Item
            name="order"
            rules={[{ required: true, message: "Please enter order" }]}
          >
            <Input size="large" placeholder="order" />
          </Form.Item> */}
   
        </Form>
      </Modal>
    </div>
  );
};

export default EditModel;
  