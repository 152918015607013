

import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Modal,
  Form,
  Input,
  Button,
  notification,
  Upload,
} from "antd";
import { EditOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { FILE_UPLOAD, PUT } from "../../utils/apiCalls";
import moment from "moment";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

interface CourseDataTablesProps {
  data: any[] | null;
  loadData: () => any; 
}

function CourseDataTables(props: CourseDataTablesProps) {
  const { data, loadData } = props;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [editingCourse, setEditingCourse] = useState<any>(null);
  const [viewingCourse, setViewingCourse] = useState<any>(null);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isLoading2, setIsLoading2] = useState(false);


  const showEditModal = (course: any) => {
    setEditingCourse(course);
    form.setFieldsValue(course);
    setIsModalVisible(true);
  };

  const showViewModal = (course: any) => {
    setViewingCourse(course);
    setIsViewModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);

      
      console.log("Form values:", );

      if (editingCourse?.id) {
        const api = `${API.UPDATE_COURSE}${editingCourse.id}`;
        setIsLoading2(false);
        const updateRequest: any = await PUT(api, values);
        console.log("API response:", updateRequest);

        if (updateRequest.status) {
          notification.success({ message: "Course updated" });
          form.resetFields();
          setIsModalVisible(false);
          loadData(); 
        } else {
          notification.error({ message: "Failed to update course" });
        }
      } else {
        notification.error({ message: "Course ID is missing" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading2(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleViewCancel = () => {
    setIsViewModalVisible(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "titleOne",
      key: "titleOne",
      render: (text: string, record: { id: any }) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => navigate(`/Admin/admin-addModules/${record.id}`)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: { id: any }) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => showViewModal(record)}
          />
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => showEditModal(record)}
          />
        </Space>
      ),
    },
  ];

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <>
      {data ? (
        data.length > 0 ? (
          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={false}
            bordered
          />
        ) : (
          <EmptyBox description="No courses available" />
        )
      ) : (
        <LoadingBox />
      )}
      {editingCourse && (
        <Modal
          title="Edit Course"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={900}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={isLoading}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" initialValues={editingCourse}>
            <Form.Item
              label="Title One"
              name="titleOne"
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Title Two" name="titleTwo">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea rows={4} />
            </Form.Item>
            <label className="formLabel">Image</label>
            <Form.Item name={"file"}>
              <Upload onChange={(data) => UploadFile(data)}>
                <div style={{ marginLeft: 10, width: "100%" }}>
                  <UploadOutlined color="red" /> Click to Upload
                </div>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {viewingCourse && (
        <Modal
          title="View Course"
          visible={isViewModalVisible}
          onCancel={handleViewCancel}
          width={900}
          footer={[
            <Button key="close" onClick={handleViewCancel}>
              Close
            </Button>,
          ]}
        >
          <p>
            <strong>Title One:</strong> {viewingCourse.titleOne}
          </p>
          <p>
            <strong>Title Two:</strong> {viewingCourse.titleTwo}
          </p>
          <p>
            <strong>Description:</strong> {viewingCourse.description}
          </p>
          <div>
            <strong>Image:</strong> <br />
            <img
              src={viewingCourse.image}
              alt="Course Image"
              style={{ maxWidth: "100%", maxHeight: "300px" }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default CourseDataTables;
