import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  UserOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { API } from "../../config/api";
import "./styles.scss";
import { GET, POST } from "../../utils/apiCalls";
import Link from "antd/es/typography/Link";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import LoadingBox from "../../components/loading";
import { updateEnrolement } from "../../redux/slice/courseSlice";

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [course, setCourse] = useState<any>({});
  const [progressData, setProgressData] = useState<any[]>([]);
  const User: any = useSelector((val: any) => val.User.user);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [enrolled, setEnrolled] = useState(false);

  const [moduleIds, setModuleIds] = useState<any>();
  const [enrollment, setEnrollement] = useState<any>({});
  const [modules, setModules] = useState<any[]>([]); // State to hold modules
  const [showModules, setShowModules] = useState(false); // State to control displaying modules
  const [courseImage, setCourseImage] = useState<string>(""); // State to hold the course image URL
  const dispatch = useDispatch();

  console.log("progressData=======", progressData);
  useEffect(() => {
    loadData();

    getEnrolement();
  }, []);

  const getEnrolement = async () => {
    try {
      let api = "Enrollments/list";
      let obj = {
        userId: Number(User?.id),
        courseId: Number(id),
        page: 1,
        take: 10,
      };
      let enrolement: any = await POST(api, obj);
      if (enrolement?.status) {
        console.log(enrolement?.data[0]);
        setEnrollement(enrolement?.data[0]);
        dispatch(updateEnrolement(enrolement?.data[0]));
      } else {
        setEnrollement({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    try {
      const api = `${API.GET_COURSE_DETAILS}${id}/${User?.id}`;
      const courseData: any = await GET(api, {});
      if (courseData?.status) {
        setCourse(courseData?.data);
        setEnrolled(courseData?.data?.enrolement);
        setCourseImage(courseData?.data?.image); // Assuming imageUrl is provided by your API
        setLoading2(false);
      } else {
        setCourse({});
        setEnrolled(false);
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const userProgress = async () => {
    try {
      let url = API.USER_PROGRESS;
      let obj = {
        userId: Number(User.id),
        type: "module",
      };

      const response: any = await POST(url, obj);
      console.log("respppp", response);
      if (response) {
        console.log("respppp", response);
        let progressArray: any = [];
        if (Array.isArray(response)) {
          response.forEach((progress: { moduleId: any }) => {
            console.log("prooo", progress);
            if (progress.moduleId) {
              setModuleIds(progress.moduleId);

              progressArray.push(progress.moduleId);
              setProgressData(progressArray);
              console.log("ab.......", progressArray);
            }
          });
        } else if (response?.moduleId) {
          console.log("09876", progressArray);
        }
        console.log("09876", progressArray);
      } else {
        setProgressData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  console.log("progressData=======", progressData);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      let api;
      let url;
      if (enrolled) {
        navigate(`/Auth/lesson/${course?.enroll?.moduleId}`);
      } else {
        api = API.CREATE_ENROLLMENTS;
        const enrollmentObj = {
          userId: Number(User.id),
          courseId: Number(id),
          enrollmentDate: new Date().toISOString(),
          progress: "1",
        };
        const enrollmentRequest: any = await POST(api, enrollmentObj);
        // url = API.POST_USER_PROGRESS;
        // const userProgressObj = {
        //   userId: Number(User.id),
        //   courseId: Number(id),

        // }

        if (enrollmentRequest.status) {
          console.log(enrollmentRequest?.data);
          dispatch(updateEnrolement(enrollmentRequest?.data));
          setEnrolled(true);
          navigate(`/Auth/modules/${course.id}`);
        } else {
          console.log("Enrollment error");
        }
        userProgress();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-box">
      {loading2 ? (
        <LoadingBox />
      ) : (
        <>
          <Card>
            <Container>
              <h1>{course?.titleOne}</h1>
              <h4 className="subtitle">{course?.titleTwo}</h4>
              <br />
              <p className="description">{course?.description}</p>

              <br />
              <Row>
                <Col md="2">
                  <Button onClick={handleButtonClick} size="large">
                    {enrolled ? "Continue" : "Start"}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card>
          <br />
        </>
      )}
    </div>
  );
};

export default Details;
