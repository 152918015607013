import React, { useEffect, useState } from "react";
import { Card, Input, Pagination, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import "./styles.scss";
import UserHeader from "../../components/userHeader";
import { useParams, useNavigate } from "react-router-dom";
import CourseCategory from "../../backup/categories/coursecategory";
import { CiSearch } from "react-icons/ci";
import CourseCategoryDataTable from "../../admin/category/dataTable";

const Category = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [courseCategoryData, setCourseCategoryData] = useState<any>(null);
  const [isLoading2, setIsLoading2] = useState(false);
  const [searchResultCount, setSearchResultCount] = useState(0);



  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadData(page, 9);
    loadCategoryData();
  }, [page]);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = `${API.GET_COURSE_CATEGORY}?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setCategories(response?.data);
        setMeta(response?.meta);
      } else {
        setCategories([]);
        notification.error({ message: "No data found" });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const loadCategoryData = async () => {
    try {
      if (!id) {
        console.error("Error: ID is undefined.");
        return;
      }
      let url = API.GET_COURSE_LIST + id;
      const courseResponse: any = await POST(url, {});
      setCourseCategoryData(courseResponse);
      setIsLoading(false);
    } catch (error) {
      console.error("Error while loading data:", error);
      setIsLoading(false);
    }
  };


  const search = async (query: string) => {
    setIsLoading2(true);
    try {
      let url = API.SEARCH_CATEGORY;
      let obj = { type: query };
      const response: any = await POST(url, obj);
      setIsLoading2(false);
      if (response?.status) {
        setCourseCategoryData(response?.data);
        setSearchResultCount(response?.meta.itemCount);
      } else {
        setCourseCategoryData([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading2(true);
    }
  };




  const handleCategoryClick = (categoryId: any) => {
    console.log(categoryId)
    navigate(`coursecategory/${categoryId}`); // Assuming the route for course category page is '/course/:categoryId'
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, pageSize);
  };

  return (
    <div className="main-box">
      <div className="headerBox">
        <UserHeader title="Categories" count={categories?.length} />
      </div>
      <br />
      <div className="main-box">
        {isLoading ? (
          <LoadingBox />
        ) : categories?.length ? (
          <div className="">
            <Card style={{ height: "100vh" }}>


            <Input
              bordered
              style={{ width: "300px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                search(e.target.value)
              }
              suffix={<CiSearch size={16} color="#000" />}
              placeholder="Search for users"
            />
            <br />
            <br />
            <CourseCategoryDataTable data={courseCategoryData} />
     

              <Row gutter={16} className="card-container">
                {categories?.map((cat: any) => (

                  
                  <Col md="3" key={cat?.id}>
                    <Card className="cat-card" onClick={() => handleCategoryClick(cat.id)}>
                      <div className="txt1">{cat?.type}</div>
                    </Card>
                    <br />
                  </Col>
                ))}
              </Row>
              <br />
              <Pagination
                current={page}
                pageSize={meta.take}
                total={meta.itemCount}
                onChange={pagination}
              />
            </Card>
          </div>
        ) : (
          <EmptyBox title={"Categories"} />
        )}
      </div>
    </div>
  );
};

export default Category;
